const cities = [
    "Parra",
    "Assagao",
    "Arpora",
    "Saipem",
    "Siolim",
    "Anjuna",
    "Candolim",
    "Reis Magos",
    "Anjun",
    "guirim",
    "Nerul",
    "Moira",
    "Pilerne",
    "Vagator",
    "Aldona",
    "Calangute",
    "Assagaon",
    "Mandrem",
    "Chapora",
    "Bastora",
];

export default cities;
